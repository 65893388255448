import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Invaze, zavlečené druhy a další terminologie" />
    <h1>Invaze, zavlečené druhy a další terminologie</h1>

<p>Invaze, zavlečen&eacute; druhy a dal&scaron;&iacute; pojmy jsou z&aacute;kladn&iacute; terminologie <Link to="/biogeografie/invazni-ekologie/">invazn&iacute; ekologie</Link>, kter&aacute; se zab&yacute;v&aacute; studiem nebezpečn&eacute;ho &scaron;&iacute;řen&iacute; nepůvodn&iacute;ch druhů a z toho plynouc&iacute;ch n&aacute;sledků. N&iacute;že naleznete v&yacute;běr pojmů t&yacute;kaj&iacute;c&iacute; se invaz&iacute; doplněn&yacute; o stručn&eacute; definice.</p>
<hr />
<h2>Původn&iacute; druhy</h2>
<p>Původn&iacute; druhy (anglicky <em>native, indigenous species</em>) jsou takov&eacute;, kter&eacute; vznikly na dan&eacute;m &uacute;zem&iacute; přirozen&yacute;mi evolučn&iacute;mi procesy bez přispěn&iacute; lidsk&eacute; aktivity a kter&eacute; se na stanovi&scaron;tě roz&scaron;&iacute;řily bez asistence, př&iacute;m&eacute; i nepř&iacute;m&eacute;, člověka.</p>
<p>Pokud se původn&iacute; druh začne nekontrolovaně &scaron;&iacute;řit jako důsledek sekund&aacute;rn&iacute; lidsk&eacute; činnosti (např. eutrofizace, tvorba nov&yacute;ch stanovi&scaron;ť), b&yacute;v&aacute; pak naz&yacute;v&aacute;n jako <strong>druh expanzivn&iacute;</strong>.</p>
<hr />
<h2>Zavlečen&eacute; (nepůvodn&iacute;) druhy</h2>
<p>Nepůvodn&iacute; či zavlečen&eacute; druhy (anglicky <em>alien, exotic, introduced, non-native, non-indigeous species</em>) jsou takov&eacute;, kter&eacute; se na dan&eacute; &uacute;zem&iacute; dostaly v důsledku činnosti člověka, ať už &uacute;myslně nebo ne&uacute;myslně. To zahrnuje i druhy, kter&eacute; se pak později &scaron;&iacute;ř&iacute; již bez přispěn&iacute; lidsk&eacute; aktivity na dal&scaron;&iacute; stanovi&scaron;tě.</p>
<h3>Invazivn&iacute; proces: invaze a jej&iacute; předstupně</h3>
<p>Invazivn&iacute; proces zahrnuje 3 z&aacute;kladn&iacute; f&aacute;ze:</p>
<ol>
<li><strong>zavlečen&iacute; </strong>(introduction)</li>
<li><strong>zdom&aacute;cněn&iacute; </strong>(naturalization)</li>
<li><strong>invaze</strong> (invasion)</li>
</ol>
<h4>1. Zavlečen&iacute;</h4>
<p>Zavlečen&iacute; (anglicky <em>introduction</em>) je proces, při kter&eacute;m je organismus nebo jeho diaspora transportov&aacute;na člověkem přes geografickou bari&eacute;ru z původn&iacute;ho are&aacute;lu do nov&eacute;ho &uacute;zem&iacute;.</p>
<h4>2. Zdom&aacute;cněn&iacute;</h4>
<p>Zdom&aacute;cněn&iacute; (anglicky <em>naturalization</em>) je proces, při kter&eacute;m se zavlečen&yacute; druh st&aacute;v&aacute; na nov&eacute;m &uacute;zem&iacute;m nez&aacute;visl&yacute;m na lidsk&eacute; činnosti a dok&aacute;že se pravidelně rozmnožovat po dlouhou dobu (a to nejm&eacute;ně 10&nbsp;let).</p>
<h4>3. Invaze</h4>
<p>Invaze (invasion) zač&iacute;n&aacute; tehdy, pokud se naturalizovan&eacute; druhy začnou rychle &scaron;&iacute;řit na velk&eacute; vzd&aacute;lenosti a zpravidla i na rozs&aacute;hl&aacute; &uacute;zem&iacute;.</p>
<p>Pro rostliny s vegetativn&iacute;m rozmnožov&aacute;n&iacute;m je br&aacute;no za minimum &scaron;&iacute;řen&iacute; alespoň 6 metrů za 3 roky od mateřsk&eacute; rostliny, u rostlinn&yacute;ch druhů s generativn&iacute;m nejm&eacute;ně 100&nbsp;metrů za 50&nbsp;let. Vět&scaron;inou je to ale mnohem v&iacute;ce.</p>
<h3>Členěn&iacute; nepůvodn&iacute;ch druhů dle invazivn&iacute;ho procesu</h3>
<p>Nepůvodn&iacute; druhy lze prim&aacute;rně dělit podle nejvy&scaron;&scaron;&iacute;ho stupně invazivn&iacute;ho procesu:</p>
<ol>
<li><strong>přechodně zavlečen&yacute; druh</strong></li>
<li><strong>naturalizovan&yacute; (zdom&aacute;cněl&yacute;) druh</strong></li>
<li><strong>invazn&iacute; druh</strong></li>
</ol>
<h4>1. Přechodně zavlečen&yacute; druh</h4>
<p>Přechodně zavlečen&yacute;ch druh (anglicky <em>casual species</em>) je nepůvodn&iacute; druh přež&iacute;vaj&iacute;c&iacute; ve voln&eacute; př&iacute;rodě jen d&iacute;ky opakovan&eacute;mu př&iacute;sunu diaspor v důsledku lidsk&eacute; činnosti. Př&iacute;klad konop&iacute; set&eacute;ho prav&eacute;ho.</p>
<h4>2. Naturalizovan&yacute; (zdom&aacute;cněl&yacute;) druh</h4>
<p>Naturalizovan&yacute; či zdom&aacute;cněl&yacute; druh (anglicky <em>naturalized or established species</em>) je nepůvodn&iacute; druh, kter&yacute; se pravidelně rozmnožuje ve voln&eacute; př&iacute;rodě po dlouhou dobu a nez&aacute;visle na lidsk&eacute; činnosti. Př&iacute;klad oře&scaron;&aacute;ku kr&aacute;lovsk&eacute;ho.</p>
<h4>3. Invazn&iacute; druh</h4>
<p>Invazn&iacute; druh (anglicky <em>invasive species</em>) je nepůvodn&iacute; naturalizovan&yacute; druh, kter&yacute; se rychle &scaron;&iacute;ř&iacute; na velk&eacute; vzd&aacute;lenosti od mateřsk&eacute; populace a zpravidla na velmi rozs&aacute;hl&eacute;m &uacute;zem&iacute;. Př&iacute;kladem je slunečnice topinambur.</p>
<h4>Pravidlo desetiny</h4>
<p>Pravidlo desetiny je statistick&yacute; odhad, s jakou pravděpodobnost&iacute; dos&aacute;hne zavlečen&yacute; druh určit&eacute;ho stadia invazn&iacute;ho procesu. Do dal&scaron;&iacute;ho stadia pronikne v průměru vždy zhruba 10&nbsp;%. Tzn. 10&nbsp;% z přechodně zavlečen&yacute;ch druhů se st&aacute;v&aacute; naturalizovan&yacute;m druhem a 10&nbsp;% z naturalizovan&yacute;ch invazivn&iacute;m.</p>
<h3>Členěn&iacute; nepůvodn&iacute;ch druhů dle doby od introdukce</h3>
<p>Nepůvodn&iacute; druhy lze podle doby od introdukce, tedy doby, kdy byly na &uacute;zem&iacute; zavlečeny, rozdělit do dvou z&aacute;kladn&iacute;ch skupin:</p>
<ol>
<li><strong>archeofyty</strong> (archaeophytes, do &uacute;zem&iacute; se dostaly před rokem 1500, tj. před objevem Ameriky, zejm&eacute;na z Bl&iacute;zk&eacute;ho a Středn&iacute;ho v&yacute;chodu či Středomoř&iacute;)</li>
<li><strong>neofyty</strong> (neophytes, do &uacute;zem&iacute; se dostaly po roce 1500, hlavně z Ameriky a Asie)</li>
</ol>
<h3>Dal&scaron;&iacute; terminologie invaze</h3>
<p>Mezi dal&scaron;&iacute;, kl&iacute;čov&eacute; terminologie invazivn&iacute; ekologie patř&iacute; tyto tři pojmy:</p>
<ul>
<li><strong>invazivnost</strong> (invasiveness, schopnost druhu invadovat)</li>
<li><strong>invazibilita</strong> (invasibility, n&aacute;chylnost společenstva k invazi)</li>
<li><strong>invadovanost</strong> (level of invasion, pozorovan&yacute; počet či pod&iacute;l nepůvodn&iacute;ch druhů ve společenstvu)</li>
</ul>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Invaze, zavlečené druhy a další terminologie</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/invazni-ekologie/"><button className="inv">&larr; Invazní ekologie</button></Link>
    <Link to="/biogeografie/invazni-ekologie/invazni-procesy-ve-svete-a-cr"><button className="inv">Invaze ve světě a ČR &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
